import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { H2, H3, Div, Button, P } from '../Elements';
import { ButtonCTA } from '../buttons/Button';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeEnablement } from '../../../contentful/content-types/blades/blade-enablement';
import sectionPadding from '../BladePadding';
import { ContentfulPage } from '../../../contentful/content-types/page';
import { MarketoFormModal } from '../Marketo/marketo-form-modal';
import { ContentfulButton } from '../buttons/ContentfulButton';
import { Divider } from '../Divider';

export function isContentfulBladeEnablement(
  blade: RelatedBlade
): blade is ContentfulBladeEnablement {
  return blade.contentTypeId === 'bladeEnablement';
}

interface EnablementBladeProps {
  blade: ContentfulBladeEnablement;
  page?: ContentfulPage;
}

const getClassnameFromBackgroundColor = new Map([
  ['blue', 'bg-blue0'],
  ['white', 'bg-white'],
]);

export const EnablementBlade: React.FC<EnablementBladeProps> = props => {
  const { blade, page } = props;
  const formModals = page?.formModal || [];
  const formModal1 = formModals.find(modal => modal.modalId === blade.buttonExtraClass);

  const formModal2 = formModals.find(modal => modal.modalId === blade.buttonModalId2);
  const [modal1IsOpen, setModal1IsOpen] = React.useState(false);

  const [modal2IsOpen, setModal2IsOpen] = React.useState(false);
  const containerClassnames = 'pt9-ns pb10-ns pt4 pb7';

  return (
    <Div className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor) || 'bg-white'}`}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
        {(blade.bladeSizeType === 'large' || !blade.bladeSizeType) && (
          <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
            <Row className={`items-center`}>
              <Col xs={12} lg={7}>
                <H2 marginBottom={[6, 7]} color={blade.textColor || 'black'}>
                  {blade.title}
                </H2>
                <Div display="flex" flexWrap="wrap">
                  {/* note: we are moving to the new button elements and deprecating the old buttons here, in order to ensure backwards compatability, we will check for and use the old buttons only if the new buttons are not present */}
                  {/* New first button missing and old first button present */}
                  {blade.buttonText && !blade.buttonOne && (
                    <ButtonCTA
                      buttonStyle="auto"
                      buttonColor={blade.buttonColor}
                      href={blade.buttonUrl}
                      className={`mr4-l mb2 mb1-l`}
                      bladeContentTypeId={blade.contentTypeId}
                      onClick={e => {
                        if (blade.buttonUrl === '#') {
                          e.preventDefault();
                          setModal1IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText}
                    </ButtonCTA>
                  )}
                  {/* New second button missing and old second button present */}
                  {blade.buttonText2 && !blade.buttonTwo && (
                    <ButtonCTA
                      buttonColor={blade.buttonColor2}
                      className={`mr4-l mb0-l`}
                      href={blade.buttonUrl2}
                      buttonStyle="auto"
                      bladeContentTypeId={blade.contentTypeId}
                      onClick={e => {
                        if (blade.buttonUrl2 === '#') {
                          e.preventDefault();
                          setModal2IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText2}
                    </ButtonCTA>
                  )}
                  {/* New first button present (old first button fields will be ignored) */}
                  {blade.buttonOne && (
                    <ContentfulButton
                      className={blade.buttonTwo ? '' : 'extra-wide-btn'}
                      bladeContentTypeId={blade.contentTypeId}
                      button={blade.buttonOne}
                    />
                  )}
                  {/* New second button present (old second button fields will be ignored) */}
                  {blade.buttonTwo && (
                    <ContentfulButton
                      className="ml4-l"
                      marginTop={[2, 0]}
                      bladeContentTypeId={blade.contentTypeId}
                      button={blade.buttonTwo}
                    />
                  )}
                </Div>
              </Col>
            </Row>
          </Div>
        )}
        {blade.bladeSizeType === 'small' && (
          <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
            <Row className={`items-center`}>
              <Col xs={12} sm={7}>
                <H3 color={blade.backgroundColor != 'white' ? 'white' : 'black'}>{blade.title}</H3>
              </Col>
              <Col xs={12} sm={5}>
                <Div marginTop={[4, 0]} display="flex" flexWrap="wrap">
                  {/* Two old buttons present, no new ones present */}
                  {blade.buttonText && blade.buttonText2 && !blade.buttonOne && !blade.buttonTwo && (
                    <>
                      {blade.buttonText && (
                        <ButtonCTA
                          buttonColor={blade.buttonColor}
                          href={blade.buttonUrl}
                          className={`mr4-l mb2 mb1-l`}
                          bladeContentTypeId={blade.contentTypeId}
                          onClick={e => {
                            if (blade.buttonUrl === '#') {
                              e.preventDefault();
                              setModal1IsOpen(true);
                            }
                          }}
                        >
                          {blade.buttonText}
                        </ButtonCTA>
                      )}
                      {blade.buttonText2 && !blade.buttonTwo && (
                        <ButtonCTA
                          buttonColor={blade.buttonColor2}
                          href={blade.buttonUrl2}
                          bladeContentTypeId={blade.contentTypeId}
                          onClick={e => {
                            if (blade.buttonUrl2 === '#') {
                              e.preventDefault();
                              setModal2IsOpen(true);
                            }
                          }}
                        >
                          {blade.buttonText2}
                        </ButtonCTA>
                      )}
                    </>
                  )}
                  {blade.buttonText && !blade.buttonText2 && !blade.buttonOne && (
                    <ButtonCTA
                      buttonStyle={'extra-wide'}
                      buttonColor={blade.buttonColor}
                      href={blade.buttonUrl}
                      className={`mb0-l`}
                      bladeContentTypeId={blade.contentTypeId}
                      onClick={e => {
                        if (blade.buttonUrl2 === '#') {
                          e.preventDefault();
                          setModal2IsOpen(true);
                        }
                      }}
                    >
                      {blade.buttonText}
                    </ButtonCTA>
                  )}
                  {blade.buttonOne && (
                    <ContentfulButton
                      className={blade.buttonTwo ? '' : 'extra-wide-btn'}
                      bladeContentTypeId={blade.contentTypeId}
                      button={blade.buttonOne}
                    />
                  )}
                  {blade.buttonTwo && (
                    <ContentfulButton
                      className="ml4-l"
                      marginTop={[2, 0]}
                      bladeContentTypeId={blade.contentTypeId}
                      button={blade.buttonTwo}
                    />
                  )}
                </Div>
              </Col>
            </Row>
          </Div>
        )}
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>

      {modal1IsOpen &&
        formModal1 &&
        formModal1.marketoForm &&
        formModal1.marketoForm.marketoFormId && (
          <MarketoFormModal
            onSuccessBehavior="show-message"
            open={modal1IsOpen}
            setOpen={setModal1IsOpen}
            modal={formModal1}
          />
        )}
      {modal2IsOpen &&
        formModal2 &&
        formModal2.marketoForm &&
        formModal2.marketoForm.marketoFormId && (
          <MarketoFormModal
            onSuccessBehavior="show-message"
            open={modal2IsOpen}
            setOpen={setModal2IsOpen}
            modal={formModal2}
          />
        )}
    </Div>
  );
};
